import { GLOBAL_FINE } from "@constants/constants";
import { CodeActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: CodeState = {
  loading: false,
  brandColor: "",
  brandImage: "",
  collaborator: {},
  currency:{},
  code: {},
};

const CodeReducer = (
  state: CodeState = INITIAL_STATE,
  action: Action
): CodeState => {
  switch (action.type) {
    case CodeActionTypes.GET_CODE_BY_KEY:
      return {
        ...state,
        code: {},
        brandColor: "",
        brandImage: "",
      };

      case CodeActionTypes.GET_CODE_BY_KEY_SUCCESS:
        const { code } = action.payload;
        const branding = code?.collaborator?.branding;
        const globalFineObject = code?.codeKey.includes(GLOBAL_FINE.code) ? GLOBAL_FINE : null;
        return {
          ...state,
          code,
          brandColor: branding?.color || "#fdc413",
          brandImage: branding?.image,
          globalFineObject,
          currency: code?.currency || {}  // Ensure this is correctly set
        };
        
    case CodeActionTypes.GET_COLLABORATOR_BY_CODE:
      return {
        ...state,
        collaborator: {},
      };

    case CodeActionTypes.GET_COLLABORATOR_BY_CODE_SUCCESS:
      const { collaborator } = action.payload;
      return {
        ...state,
        collaborator: collaborator,
      };

    case CodeActionTypes.SET_CODE_LOADER:
      return {
        ...state,
        loading: action.payload,
      };

    case CodeActionTypes.RESET_CODE:
      return {
        ...state,
        code: {},
        brandColor: "",
        brandImage: "",
      };

    case CodeActionTypes.SET_DEFAULT_BRAND_COLOR:
      return {
        ...state,
        brandColor: action.payload,
      };

    default:
      return state;
  }
};

export default CodeReducer;
