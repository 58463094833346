export const AppActionTypes = {
  APP_LOADING: "APP_LOADING",
};

export const CategoryActionTypes = {
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_CATEGORY_MAXIMUM_RANGE: "GET_CATEGORY_MAXIMUM_RANGE",
  GET_CATEGORY_MAXIMUM_RANGE_SUCCESS: "GET_CATEGORY_MAXIMUM_RANGE_SUCCESS",

  SET_CATEGORY_LOADER: "SET_CATEGORY_LOADER",
};

export const ProductActionTypes = {
  GET_FILTERED_PRODUCT: "GET_FILTERED_PRODUCT",
  GET_FILTERED_PRODUCT_SUCCESS: "GET_FILTERED_PRODUCT_SUCCESS",
  GET_MUNICIPLES: "GET_MUNICIPLES",
  GET_MUNICIPLES_SUCCESS: "GET_MUNICIPLES_SUCCESS",
  GET_FILTERED_PRODUCT_PRICES: "GET_FILTERED_PRODUCT_PRICES",
  GET_FILTERED_PRODUCT_PRICES_EXTRA: "GET_FILTERED_PRODUCT_PRICES_EXTRA",
  GET_FILTERED_PRODUCT_PRICES_SUCCESS: "GET_FILTERED_PRODUCT_PRICES_SUCCESS",
  SET_PRODUCT_OVERCOSTS: "SET_PRODUCT_OVERCOSTS",
  SET_PRODUCT_FILTER: "SET_PRODUCT_FILTER",
  SET_PRODUCT_IRIS_EXTRA_SELECTED: "SET_PRODUCT_IRIS_EXTRA_SELECTED",

  SET_PRODUCT_LOADER: "SET_PRODUCT_LOADER",
};

export const PolicyActionTypes = {
  CREATE_POLICY: "CREATE_POLICY",
  SET_UPDATE_PASSWORD_MODAL: "SET_UPDATE_PASSWORD_MODAL",
  GET_POLICIES: "GET_POLICIES",
  GET_POLICIES_SUCCESS: "GET_POLICIES_SUCCESS",
  GET_POLICY_BY_ID: "GET_POLICY_BY_ID",
  GET_POLICY_BY_ID_SUCCESS: "GET_POLICY_BY_ID_SUCCESS",
  GET_GENERATED_POLICY_PDF: "GET_GENERATED_POLICY_PDF",
  GET_GENERATED_POLICY_PDF_SUCCESS: "GET_GENERATED_POLICY_PDF_SUCCESS",

  SET_POLICY_LOADER: "SET_POLICY_LOADER",
};

export const BudgetActionTypes = {
  CREATE_BUDGET: "CREATE_BUDGET",
  SET_BUDGET_ID: "SET_BUDGET_ID",

  SET_BUDGET_LOADER: "SET_BUDGET_LOADER",
};

export const CodeActionTypes = {
  GET_CODE_BY_KEY: "GET_CODE_BY_KEY",
  GET_CODE_BY_KEY_SUCCESS: "GET_CODE_BY_KEY_SUCCESS",
  CREATE_VISIT: "CREATE_VISIT",
  RESET_CODE: "RESET_CODE",
  GET_COLLABORATOR_BY_CODE: "GET_COLLABORATOR_BY_CODE",
  GET_COLLABORATOR_BY_CODE_SUCCESS: "GET_COLLABORATOR_BY_CODE_SUCCESS",
  SET_DEFAULT_BRAND_COLOR: "SET_DEFAULT_BRAND_COLOR",

  SET_CODE_LOADER: "SET_CODE_LOADER",
};

export const UserActionTypes = {
  GET_MUNICIPALS: "GET_MUNICIPALS",
  GET_MUNICIPALS_SUCCESS: "GET_MUNICIPALS_SUCCESS",
  GET_USER: "GET_USER",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_POINTS: "GET_USER_POINTS",
  GET_USER_POINTS_SUCCESS: "GET_USER_POINTS_SUCCESS",
  CREATE_PASSWORD: "CREATE_PASSWORD",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  LOGIN: "LOGIN",
  CHECK_USER_EXISTS: "CHECK_USER_EXISTS",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  AUTO_LOGIN: "AUTO_LOGIN",

  SET_USER_LOADER: "SET_USER_LOADER",
};

export const AdvantageActionTypes = {
  GET_ADVANTAGES: "GET_ADVANTAGES",
  GET_ADVANTAGES_SUCCESS: "GET_ADVANTAGES_SUCCESS",
  GET_ADVANTAGE_BY_ID: "GET_ADVANTAGE_BY_ID",
  GET_ADVANTAGE_BY_ID_SUCCESS: "GET_ADVANTAGE_BY_ID_SUCCESS",

  SET_ADVANTAGE_LOADER: "SET_ADVANTAGE_LOADER",
};

export const RewardActionTypes = {
  GET_REWARDS: "GET_REWARDS",
  GET_REWARDS_SUCCESS: "GET_REWARDS_SUCCESS",
  CLAIM_REWARD: "CLAIM_REWARD",

  SET_REWARD_LOADER: "SET_REWARD_LOADER",
};
