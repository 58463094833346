import { PRODUCT_PROVIDERS } from "@helpers/data";
import { MenuItem, TextField } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  getFilteredProductPrices,
  setIrisExtraSelected,
} from "@store/product/ProductActions";
import { useState, useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import ItemTitle from "./ItemTitle";
import { format } from "date-fns";
import { GLOBAL_FINE, IRIS_DEROGATION_70_80, IRIS_DEROGATION_70_80_CRUCERO, IRIS_DEROGATION_CRUCERO } from "@constants/constants";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@emotion/react";

interface IrisExtraRowItemProps {
  className?: string;
  item?: any;
  type: string;
  singleItem?: boolean;
}

const IrisExtraRowItem: React.FC<IrisExtraRowItemProps> = (props) => {
  const { className, item, type, singleItem } = props;
  const [searchParams] = useSearchParams();
  const variantParams: any = searchParams.get("variant");
  const theme: any = useTheme();

  const { code }: any = useSelector<any>(({ Travelfine }) => Travelfine.code);

  const isExistCode = code?.codeKey?.toUpperCase().includes(GLOBAL_FINE.code?.toUpperCase()) ?? false;
  const colorSelect = isExistCode ? theme.palette.secondary.main : '#fdc413';

  const dispatch = useDispatch();

  const [value, setValue] = useState<any>("No");

  const list = useMemo(() => {
    return [
      { label: "No", value: "No" },
      { label: "Si", value: item?.id },
    ];
  }, [item?.id]);

  const { irisExtraSelected, product, filter, productOverCosts }: any =
    useSelector<any>(({ Travelfine }) => Travelfine.product);

  useEffect(() => {
    setValue(
      irisExtraSelected[type]?.id === item?.id
        ? irisExtraSelected[type]?.id
        : "No"
    );
  }, [type, irisExtraSelected, item?.id]);

  const changeHandler = (event: any) => {
    const newIrisExtraSelected = JSON.parse(JSON.stringify(irisExtraSelected));

    const newSelectedObj =
      event.target.value === "No"
        ? null
        : {
          id: event.target.value,
          title: item.title,
          derogation: item.derogation,
        };

    newIrisExtraSelected[type] = newSelectedObj;

    dispatch(setIrisExtraSelected(newIrisExtraSelected));

    const extras = productOverCosts[type];
    let irisExtraAll = [];
    const irisSelected = newIrisExtraSelected[type];

    if (product?.providers[type] === PRODUCT_PROVIDERS.IRIS) {
      if (irisSelected && irisSelected.derogation === IRIS_DEROGATION_CRUCERO) {
        irisExtraAll = product.irisExtras.filter((extra: any) => extra.derogation === IRIS_DEROGATION_70_80_CRUCERO || extra?.derogation === IRIS_DEROGATION_CRUCERO);
      } else if (irisSelected) {
        irisExtraAll = product?.irisExtras?.filter((extra: any) => extra?.derogation === IRIS_DEROGATION_70_80);
      }
      product.irisExtraAll = irisExtraAll;
    }

    dispatch(
      getFilteredProductPrices({
        productId: product.productIds[type],
        productKey: product?._id ?? null,
        codeKey: code?.codeKey ?? null,
        extras,
        filter: {
          origin: filter.origin,
          destination: filter.destination,
          departureDate: format(new Date(filter.departureDate), "yyyy-MM-dd"),
          returnDate: format(new Date(filter.returnDate), "yyyy-MM-dd"),
          totalTravellers: filter.totalTravellers,
        },
        variant: type,
        variants: product.variants,
        irisExtraSelected: newSelectedObj,
        irisExtraAll: product.irisExtraAll ?? []
      })
    );

    setValue(event?.target?.value);
  };

  return (
    <td
      className={` ${variantParams === type ? 'border-2' : 'border'} py-2 text-center px-2 ${className}`}
      style={{
        borderColor: `${variantParams === type ? colorSelect : ''}`,
        borderTopColor: 'rgb(243 244 246)',
        borderBottomColor: 'rgb(243 244 246)'
      }}
    >
      <div className="flex items-center justify-between lg:block">
        {item && singleItem && <ItemTitle item={item} />}

        {product.providers[type] !== PRODUCT_PROVIDERS.IRIS ? (
          <CancelIcon className="text-gray-400 fill-current" />
        ) : (
          <TextField
            className={`capitalize`}
            variant={"outlined"}
            select
            onChange={changeHandler}
            value={value}
            size={"small"}
          >
            {list.map((listItem: any) => {
              return (
                <MenuItem key={listItem.value} value={listItem.value}>
                  {listItem.label}
                </MenuItem>
              );
            })}
          </TextField>
        )}
      </div>
    </td>
  );
};

export default IrisExtraRowItem;
