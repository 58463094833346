import { differenceInYears } from "date-fns";
import { useCallback } from "react";

const usePriceAndAddiontalFeesCalculator = ({
  priceWithoutAdditionalFees,
  totalTravellers,
  watch,
  isInsuredPolicyHolder,
  discountPercentage,
  eldersHaveAdditionalFees,
  elderAdditionalFeePercentage,
  additionalFeeStartingAge,
}: {
  priceWithoutAdditionalFees: number;
  totalTravellers: number;
  isInsuredPolicyHolder: boolean;
  watch: Function;
  discountPercentage: number;
  eldersHaveAdditionalFees: boolean;
  elderAdditionalFeePercentage: number;
  additionalFeeStartingAge: number;
}) => {
  const travellers = watch("travellers");
  const policyHolderBirthdate = watch("dob");
  const nowDate = new Date();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const elderCount = useCallback(()=> getNumberOfElder(),[watch()]) ;

  const PricePerPerson = priceWithoutAdditionalFees / totalTravellers;
  const additionalFeeDueElders =
    ((PricePerPerson * elderAdditionalFeePercentage) / 100) * elderCount();

  function getNumberOfElder() {
    let output = 0;
    const birthdate = new Date(policyHolderBirthdate);

    if (
      isInsuredPolicyHolder &&
      differenceInYears(nowDate, birthdate) >=
        additionalFeeStartingAge
    ) {
      output += 1;
    }
    
    travellers.forEach((traveller: any) => {
      const travellerDate = new Date(traveller.dob);
      if (
        differenceInYears(nowDate, travellerDate) > additionalFeeStartingAge
      ) {
        output += 1;
      }
    });

    return output;
  }

  const totalPriceBeforeDiscount = getTotalPrice();
  const totalPriceAfterDiscount =Number(getFinalPriceAfterDiscount(totalPriceBeforeDiscount)?.toFixed(2));
  const totalPriceAfterWithoutDiscount = getFinalPriceAfterDiscount(totalPriceBeforeDiscount,true);


  function getTotalPrice() {
    let output = priceWithoutAdditionalFees;

    if (eldersHaveAdditionalFees) {
      output += additionalFeeDueElders;
    }
    return output;
  }

    function getFinalPriceAfterDiscount(totalPrice: number, isWithoutDiscountt: boolean = false) {
    let output = totalPrice;

    if (discountPercentage && !isWithoutDiscountt) {
      output -= (discountPercentage * output) / 100;
    }

    return output;
  }

  return {
    elderCount,
    additionalFeeDueElders,
    totalPriceBeforeDiscount,
    totalPriceAfterDiscount,
    totalPriceAfterWithoutDiscount
  };
};

export { usePriceAndAddiontalFeesCalculator };
