import React, { useEffect, useState, useRef } from 'react';
import { MenuItem, Select } from '@mui/material';
import { calculateBadgeSpecific } from '@api/Endpoint';
import { axiosInstance } from '@api/axios';
import { useSelector } from 'react-redux';

interface CurrencySelectorProps {
  variant: string;
  price: any;
  priceLabel: any;
  currencies: { label: string; value: string; symbol: string }[];
  selectedCurrency: string;
  onChange: (currency: string, variant: string) => void;
  setSelectedCurrencies: any;
  setConvertedPrices: any;
  className: any;
}

const CurrencySelector: React.FC<CurrencySelectorProps> = ({
  variant,
  price,
  priceLabel,
  currencies,
  selectedCurrency,
  onChange,
  setSelectedCurrencies,
  setConvertedPrices,
  className
}) => {
  const [priceSelect, setPriceSelect] = useState<string | null>(null);
  const [isChangePrice, setIsChangePrice]= useState<boolean | null>(false);
  const [currencyAbout, setCurrencyAbout] = useState<string | null>(null);
  const { code }: any = useSelector<any>(({ Travelfine }) => Travelfine.code);
  const priceCache = useRef<Map<string, any>>(new Map());

  useEffect(() => {
    const formattedPrice = `${Number.parseFloat(price).toFixed(2)} ${code?.currency?.symbol}`;
    if (priceSelect !== formattedPrice) {
      setPriceSelect(formattedPrice);
      setCurrencyAbout(null);
      setSelectedCurrencies(code?.currency?.value, variant);
      setIsChangePrice(true)
      setConvertedPrices((prevPrices:any) => ({
        ...prevPrices,
        [variant]: Number.parseFloat(price),
      }));
    }
  }, [price]);

  const fetchPrices = async (selectCurrency: string, variant:string) => {
    if (!code) return;

    const currency = code?.currency ?? null;
    const badges = code.badges ?? [];
    const cacheKey = `${currency?.value}-${selectCurrency}`;

    if (priceCache.current.has(cacheKey) && !isChangePrice) {
      setPriceSelect(priceCache.current.get(cacheKey)?.label || '');
      setConvertedPrices((prevPrices:any) => ({
        ...prevPrices,
        [variant]: priceCache.current.get(cacheKey)?.price,
      }));
      return;
    }

    if (currency && badges.length > 0) {
      try {
        if (currency?.value === selectCurrency) {
          setPriceSelect(priceLabel);
          setConvertedPrices((prevPrices:any) => ({
            ...prevPrices,
            [variant]:Number.parseFloat(price),
          }));
        } else {
          const response = await axiosInstance.get(
            calculateBadgeSpecific(
              badges.map((data: any) => data.value).join(','),
              priceSelect ?? price,
              selectCurrency
            )
          );

          const conversionPrice: any = Object.values(response?.data?.data).find(
            (conversion: any) =>
              conversion.from.includes(currencyAbout ? currencyAbout : currency.value) &&
              conversion.to.includes(selectCurrency)
          );

          if (conversionPrice) {
            const convertedPrice = `${Number.parseFloat(conversionPrice.value).toFixed(2)} ${badges.find((current: any) => current.value === selectCurrency)?.symbol}`;
            setPriceSelect(convertedPrice);
            priceCache.current.set(cacheKey,{ label:convertedPrice,
              price: Number.parseFloat(conversionPrice.value)
            });
            setConvertedPrices((prevPrices:any) => ({
              ...prevPrices,
              [variant]: Number.parseFloat(conversionPrice.value).toFixed(2),
            }));
          }
        }
      } catch (err: any) {
        console.error('Failed to fetch prices:', err.message);
      }
    }
    setCurrencyAbout(selectCurrency);
    setIsChangePrice(false)
  };

  const handleCurrencyChange = async (event: any) => {
    const currency = event.target.value as string;
    await fetchPrices(currency, variant);
    
    onChange(currency, variant);
  };

  return (
    <div className="flex items-center justify-center gap-4 mt-2 ml-1 font-normal text-gray-500 text-md">
      {priceSelect || priceLabel}
      <Select
        value={selectedCurrency}
        onChange={handleCurrencyChange}
        displayEmpty
        className={`${className} cursor-pointer`}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {currencies.map((currency) => (
          <MenuItem key={currency.value} value={currency.value}>
            {currency?.value}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default CurrencySelector;
