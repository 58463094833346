import { ProductActionTypes } from "../redux/actionTypes";

export const getFilteredProduct = (payload: any) => ({
  type: ProductActionTypes.GET_FILTERED_PRODUCT,
  payload,
});

export const getFilteredProductSuccess = (payload: any) => ({
  type: ProductActionTypes.GET_FILTERED_PRODUCT_SUCCESS,
  payload,
});

export const getFilteredProductPrices = (payload: any) => ({
  type: ProductActionTypes.GET_FILTERED_PRODUCT_PRICES,
  payload,
});

export const getFilteredPricesExtra = (payload: any) => ({
  type: ProductActionTypes.GET_FILTERED_PRODUCT_PRICES_EXTRA,
  payload,
});

export const getFilteredProductPricesSuccess = (payload: any) => ({
  type: ProductActionTypes.GET_FILTERED_PRODUCT_PRICES_SUCCESS,
  payload,
});

export const setProductFilter = (payload: any) => ({
  type: ProductActionTypes.SET_PRODUCT_FILTER,
  payload,
});

export const setProductOverCosts = (payload: any) => ({
  type: ProductActionTypes.SET_PRODUCT_OVERCOSTS,
  payload,
});

export const setIrisExtraSelected = (payload: any) => ({
  type: ProductActionTypes.SET_PRODUCT_IRIS_EXTRA_SELECTED,
  payload,
});

export const setProductLoader = (payload: any) => ({
  type: ProductActionTypes.SET_PRODUCT_LOADER,
  payload,
});
