import React from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import { ImageAsset, Input } from "@components";
import { Paper } from "@mui/material";

interface CheckoutFormProps {
  errors: any;
  control: any;
}

const CheckoutForm: React.FC<CheckoutFormProps> = (props) => {
  const { errors, control } = props;

  const iframeStyles = {
    base: {
      fontSize: "16px",
      fontFamily: "Poppins, sans-serif",
      fontWeight: "300",
    },
  };

  const styleOptions = {
    style: iframeStyles,
  };

  return (
    <form>
      <div className="flex flex-col items-start mt-3 mb-3 space-y-3 lg:flex-row lg:space-x-3 lg:space-y-0">
        <div className="flex flex-col w-full lg:w-3/12">
          <Input
            label="Titular de la tarjeta"
            name="cardHolderName"
            placeholder="John Doe"
            control={control}
            fullWidth
            error={!!errors.cardHolderName}
            errorMessage={errors?.cardHolderName?.message}
            className="w-full"
            size="small"
            required
            labelOutside
          />
        </div>
        <div className="flex flex-col w-full lg:w-3/12">
          <label className="text-sm font-medium whitespace-nowrap">
            Numero de la tarjeta*
          </label>
          <div className="border border-gray-300 bg-white hover:border-black rounded-md px-4 py-2.5 w-full">
            <CardNumberElement options={styleOptions} />
          </div>
        </div>
        <div className="flex flex-col w-full lg:w-3/12">
          <label className="text-sm font-medium whitespace-nowrap">
            Fecha de caducidad*
          </label>
          <div className="border border-gray-300 bg-white hover:border-black rounded-md px-4 py-2.5 w-full">
            <CardExpiryElement options={styleOptions} />
          </div>
        </div>
        <div className="flex flex-col w-full lg:w-3/12">
          <label className="text-sm font-medium whitespace-nowrap">
            Código de seguridad*
          </label>
          <div className="border border-gray-300 bg-white hover:border-black rounded-md px-4 py-2.5 w-full">
            <CardCvcElement options={styleOptions} />
          </div>
        </div>
      </div>
    </form>
  );
};

interface StripeFormProps {
  errors: any;
  control: any;
}

const StripeForm: React.FC<StripeFormProps> = (props) => {
  const { errors, control } = props;
  return (
    <div>
      <p className="text-xl font-bold">Datos de pago</p>
      <Paper elevation={3} className="flex flex-col px-4 py-4 mt-4 rounded-lg">
        <CheckoutForm errors={errors} control={control} />
        <div className="flex items-center space-x-2">
          <ImageAsset className="w-5 h-5" src="lockIcon" />
          <p className="mt-1 text-sm text-gray-400">
            Tu pago está encriptado y securizado
          </p>
        </div>
      </Paper>
    </div>
  );
};

export default StripeForm;
