import { SelectAutoComplete, DatePicker, Button, Input } from "@components";
import {
  originList,
  travellerList,
  ANNUAL_CATEGOIRES,
  getCountriesListSpanish,
  getOriginCountry,
  MID_CATEGOIRES,
} from "@helpers/data";
import { useEffect, useState } from "react";
import { add, differenceInDays, differenceInYears } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryMaximumRange } from "@store/category/CategoryActions";
import { CODE_MEXICO, HEALTH_INSURANCE_RETURN_DAY_MAXIMUM_RANGE, HEALTHINSURANCE, MID_MAXIMUM_RANGE, STUDENT_MEX, WORKANDHOLIDAY } from "@constants/constants";
// import { sub } from "date-fns/esm";

interface FilterFormProps {
  control: any;
  handleSubmit: any;
  onSubmit: any;
  isValid: any;
  errors: any;
  watch: any;
  setValue: any;
  categoriesList: any;
  getValues: any;
  applyCollaboratorPanelStyles: boolean;
}

const WORK_AND_HOLIDAY_RETURN_DAY_MAXIMUN_RANGE = 364;

const FilterForm: React.FC<FilterFormProps> = (props) => {
  const {
    control,
    handleSubmit,
    onSubmit,
    errors,
    watch,
    setValue,
    categoriesList,
    getValues,
    applyCollaboratorPanelStyles,
  } = props;
  const filterDepartureDate = watch("departureDate");
  const filterCategory = watch("modality");
  const dispatch = useDispatch();
  const [listOrigin, setOriginList] = useState<any[]>(originList)
  const [listDestination, setDestinationList] = useState<any[]>([])

  const { categoryMaximumRange }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.category
  );

  useEffect(() => {
    if (filterCategory.value) {
      const filterTitle = filterCategory?.label ?? null;
      setOriginList(getOriginCountry(setValue, filterCategory?.key, originList, watch('origin'), filterTitle));
      setDestinationList(getCountriesListSpanish(filterCategory?.key, watch('destination'), setValue, filterTitle));
      dispatch(getCategoryMaximumRange({ id: filterCategory.value }));

      if (ANNUAL_CATEGOIRES.includes((filterCategory.key))) {
        const returnDate = add(filterDepartureDate, {
          days: 364,
        });
        setValue('returnDate', returnDate);
      }
      if (filterCategory?.key === STUDENT_MEX) {
        if (watch('destination')?.code === CODE_MEXICO) {
          setValue("destination", { label: "Todo el mundo", value: "Todo el mundo", code: "WR" });
        }
      }
    }
  }, [dispatch, filterCategory.value]);

  useEffect(() => {
    const returnDate = getValues("returnDate");

    if (filterCategory?.key === "AVIRISAnualMultiviaje") {
      let returnDate = add(filterDepartureDate, {
        days: 365,
      });
      setValue("returnDate", returnDate);
      return;
    }

    if (ANNUAL_CATEGOIRES.includes(filterCategory?.key)) {
      let returnDate = add(filterDepartureDate, {
        days: 364,
      });
      setValue("returnDate", returnDate);
      return;
    }

    if (MID_CATEGOIRES.includes(filterCategory?.key)) {
      setValue("returnDate", filterDepartureDate);
      return;
    }

    if (differenceInYears(returnDate, filterDepartureDate) >= 1) {
      let returnDate = add(filterDepartureDate, {
        days: 364,
      });

      setValue("returnDate", returnDate);
      return;
    }
    if (filterDepartureDate > returnDate) {
      setValue("returnDate", filterDepartureDate);
      return;
    }
  }, [filterCategory, setValue, filterDepartureDate, getValues]);

  useEffect(() => {
    const returnDate = getValues("returnDate");
    if (
      differenceInDays(returnDate, filterDepartureDate) + 1 >
      categoryMaximumRange
    ) {
      const newReturnDate = add(filterDepartureDate, {
        days: categoryMaximumRange,
      });
      setValue("returnDate", newReturnDate);
    }
  }, [
    filterCategory,
    filterDepartureDate,
    categoryMaximumRange,
    setValue,
    getValues,
  ]);

  let maxDate = add(filterDepartureDate, {
    days:
      filterCategory?.key === WORKANDHOLIDAY || ANNUAL_CATEGOIRES.includes(filterCategory?.key)
        ? WORK_AND_HOLIDAY_RETURN_DAY_MAXIMUN_RANGE
        : filterCategory?.key === HEALTHINSURANCE ? HEALTH_INSURANCE_RETURN_DAY_MAXIMUM_RANGE
          : MID_CATEGOIRES.includes(filterCategory?.key) ? MID_MAXIMUM_RANGE : categoryMaximumRange,
  });

  const modalityLabel = (MID_CATEGOIRES.includes(filterCategory?.key) || filterCategory?.key === 'MultiviajeAnual') ? 'Tipo de Plan' : 'modalidad';

  return (
    <form
      name="productForm"
      noValidate
      className="flex flex-col items-center justify-around w-full lg:items-start lg:flex-row"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col w-full">
        <div className="flex flex-col items-center justify-around w-full lg:items-start lg:flex-row">
          <SelectAutoComplete
            smallTextsInBigScreens={applyCollaboratorPanelStyles}
            label={modalityLabel}
            name="modality"
            control={control}
            variant="filled"
            fullWidth
            className="w-full mb-2 lg:mb-0 lg:ml-2"
            list={categoriesList}
            size="small"
            disableClearable
            saveObject
          />
          <SelectAutoComplete
            smallTextsInBigScreens={applyCollaboratorPanelStyles}
            saveObject
            label="origen"
            name="origin"
            control={control}
            variant="filled"
            fullWidth
            className="w-full mb-2 lg:mb-0 lg:ml-2"
            list={listOrigin}
            size="small"
            disableClearable
            groupBy={(option: any) => option.groupTitle}
          />
          <SelectAutoComplete
            smallTextsInBigScreens={applyCollaboratorPanelStyles}
            saveObject
            label="destino"
            name="destination"
            control={control}
            variant="filled"
            fullWidth
            className="w-full mb-2 lg:mb-0 lg:ml-2"
            list={listDestination}
            size="small"
            disableClearable
            groupBy={(option: any) => option.groupTitle}
          />

          <DatePicker
            smallTextsInBigScreens={applyCollaboratorPanelStyles}
            name="departureDate"
            control={control}
            variant="filled"
            fullWidth={true}
            className="w-full mb-2 lg:mb-0 lg:ml-2"
            size="small"
            label="Fecha de salida"
            minDate={new Date()}
            disableTextField
          />
          <DatePicker
            smallTextsInBigScreens={applyCollaboratorPanelStyles}
            name="returnDate"
            control={control}
            variant="filled"
            fullWidth={true}
            error={!!errors.returnDate}
            errorMessage={errors?.returnDate?.message}
            className="w-full mb-2 lg:mb-0 lg:ml-2"
            size="small"
            label="Fecha de retorno"
            minDate={filterDepartureDate}
            maxDate={maxDate}
            disabled={
              ANNUAL_CATEGOIRES.includes(filterCategory?.key) ||
              filterCategory?.key === "AVIRISAnualMultiviaje"
            }
            disableTextField
          />
          <SelectAutoComplete
            smallTextsInBigScreens={applyCollaboratorPanelStyles}
            label="viajeros"
            name="totalTravellers"
            control={control}
            variant="filled"
            fullWidth
            className="w-full mb-2 lg:mb-0 lg:ml-2"
            list={travellerList}
            size="small"
            disableClearable
            labelCenter
          />
        </div>
        <div className="flex justify-center">
          <Input
            label="Viajero en destino"
            name="userInDestination"
            control={control}
            fullWidth
            error={!!errors.userInDestination}
            errorMessage={errors?.userInDestination?.message}
            size="small"
            type="checkbox"
          />
        </div>
      </div>
      <Button
        className="w-40 mt-6 lg:ml-2 whitespace-nowrap lg:min-w-max lg:mt-0 lg:py-3"
        type="submit"
        style={{ height: applyCollaboratorPanelStyles ? "38.8px" : "auto" }} //Normal className is not working, rem magnitude isn't working neither
      >
        Dame el mejor precio
      </Button>
    </form>
  );
};

export default FilterForm;
